export const digiVKLocales: string[] = [
    'LL_DIGIVK_Headline_1',
    'LL_DIGIVK_Headline_2',
    'LL_DIGIVK_Subline_1',
    'LL_DIGIVK_Subline_2',
    'LL_DIGIVK_Copy_1',
    'LL_DIGIVK_Copy_2',
    'LL_DIGIVK_Konfig_1',
    'LL_DIGIVK_Konfig_1_Copy_1',
    'LL_DIGIVK_Konfig_2', // E-Books / Flipbooks veröffentlichen
    'LL_DIGIVK_Konfig_2_Copy_1', // E-Books des eigenen Accounts freischalten
    'LL_DIGIVK_Konfig_2_Copy_2', // E-Books des gesamten Büros freischalten
    'LL_DIGIVK_Ebook_Copy_1',
    'LL_DIGIVK_Konfig_3', // Eigene Kurzvorstellung veröffentlichen
    'LL_DIGIVK_Konfig_3_Copy_1', // Kurzvorstellung veröffentlichen
    'LL_DIGIVK_Konfig_3_Mustertext_Input_Headline', // Kurzvorstellung Titel
    'LL_DIGIVK_Konfig_4', // Weblinks und externe Seiten
    'LL_DIGIVK_Konfig_5',
    'LL_DIGIVK_Konfig_4_Copy_1', // Meine Kollegen können auch meine Kontaktdaten auf anderen Microsites veröffentlichen
    'LL_DIGIVK_Konfig_4_Copy_2', // Freigeschaltete Kollegen auf meiner Microsite anzeigen
    'LL_DIGIVK_Speichern',
    'LL_DIGIVK_Status',
    'LL_DIGIVK_Status_02',
    'LL_DIGIVK_Konfig_6', // QR zum Scannen
    'LL_DIGIVK_Konfig_6_Copy_2b', // Beispiel - einfach QR-Code
    'LL_DIGIVK_Alias_Label', // Card-Alias
    'LL_DIGIVK_Alias_Notice_Length', // Maximal 40 Zeichen
    'LL_DIGIVK_Alias_Toast_Locked', // Die Card-ID'Die Card-ID kann nicht geändert werden, ' +'da sie bereits veröffentlicht wurde. ' +'Solltest du das dennoch wünschen setze dich bitte mit dem Support in Verbindung.'
    'LL_DIGIVK_Alias_Notice', //  Die Änderung hat Auswirkungen auf die Web-Adresse der Karte.
    'LL_DIGIVK_Ebook_BuyNowCTA', // Flipbook bestellen
    'LL_DIGIVK_Konfig_3_Mustertext_Input_Placeholder', // Hier kannst du deine Kurzvorstellung bearbeiten.
    'LL_DIGIVK_MyLinks_Delete_Confirm', // Möchtest du diesen Link wirklich löschen?
    'LL_DIGIVK_MyLinks_Label_Placeholder', // Anzeigetext
    'LL_DIGIVK_MyLinks_Url_Placeholder', // URL / https://...
    'LL_DIGIVK_MyLinks_Url_Validation', //Bitte gebe eine valide URL mit https://... ein
    'LL_DIGIVK_MyLinks_Statistics_Text', // Dieser Link wurde [count]x geglickt. {Serkan: [count] muss erhalten bleiben}
    'LL_DIGIVK_MyLinks_MaxLinks_Text', // Maximale Anzahl an Links ([count] / [limit]) erreicht
    'LL_DIGIVK_MyLinks_Add', // Neuen Link einfügen
    'LL_DIGIVK_Status_Booked_Until', // Buchung läuft bist zum
    'LL_DIGIVK_Status_Booked_Type', // Buchungstyp
    'LL_DIGIVK_Booking_365Days', // Verländerung um 365 Tage
    'LL_DIGIVK_Booking_BookNow', // Jetzt buchen
    'LL_DIGIVK_Statistics_Headline', // Statistiken
    'LL_DIGIVK_Statistics_Copy_1',
    'LL_DIGIVK_Statistics_Calls_Total', // Aufrufe insgesamt
    'LL_DIGIVK_Statistics_Calls_For', // Aufrufe für
    'LL_DIGIVK_Download_AsImage', // Download as Bild
    'LL_DIGIVK_Preview_Headline', // Vorschau mit deinen Daten
    'LL_DIGIVK_Preview_Headline_Demo', // Vorschau mit Demo-Daten
    'LL_DIGIVK_Card_Saved_Successful', // Karte erfolgreich gespeichert
    'LL_DIGIVK_Card_Privacy_Text', // Hiermit akzeptiere ich die
    'LL_DIGIVK_Card_Privacy_ButtonLabel', // Datenschutzrichtlinien
    'Loc_Digi_KampagnenVerwaltung_Status_Inaktiv_01',
    'Loc_Digi_KampagnenVerwaltung_Status_Aktiv_01',
    'LL_DIGIVK_Status_Zustand_01',
    'LL_DIGIVK_Status_Zustand_02',
    'LL_DIGIVK_Status_Zustand_03',
    'LL_DIGIVK_Statistics_Calls_Total',
    'LL_DIGIVK_Konfig_QRDisplay',
];
