import { useLocalization } from '../api/gql/useLocalization';
import { digiVKLocales } from './localesDigiVK';

const localeArray = [
    'LL_Breadcrumps_Start',
    'LL_General_Menue_Kontakt_Button_2',
    'LL_Favorites_Headline',
    'LL_Land_AL',
    'LL_Land_AT',
    'LL_Land_BE',
    'LL_Land_BA',
    'LL_Land_BG',
    'LL_Land_HR',
    'LL_Land_CY',
    'LL_Land_CZ',
    'LL_Land_DK',
    'LL_Land_EE',
    'LL_Land_FI',
    'LL_Land_FR',
    'LL_Land_DE',
    'LL_Land_GR',
    'LL_Land_HU',
    'LL_Land_IS',
    'LL_Land_IE',
    'LL_Land_IL',
    'LL_Land_IT',
    'LL_Land_LV',
    'LL_Land_LT',
    'LL_Land_LU',
    'LL_Land_ME',
    'LL_Land_MK',
    'LL_Land_MT',
    'LL_Land_NL',
    'LL_Land_NO',
    'LL_Land_PL',
    'LL_Land_PT',
    'LL_Land_RO',
    'LL_Land_RS',
    'LL_Land_SK',
    'LL_Land_SI',
    'LL_Land_ES',
    'LL_Land_SE',
    'LL_Land_CH',
    'LL_Land_TR',
    'LL_Land_GB',
    'LL_Footer_Impressum',
    'LL_Footer_Datenschutz',
    'LL_Footer_Widerrufsbelehrung',
    'LL_Footer_AGB',
    'LL_General_Menue_Kontakt_Button_1',
    'LL_ServiceInfo_Headline',
    'LL_ServiceInfo_Copytext_1',
    'LL_ServiceInfo_Copytext_2',
    'LL_ServiceInfo_Copytext_3',
    'LL_Kontaktformular_Support',
    'LL_Header_Themen',
    'LL_Themen_Sidebar_MyREMAXOffice',
    'LL_Header_Themen_Objectvermarktung',
    'LL_Header_Themen_SelfPromotion',
    'LL_AgentOnb_Kategorie_Navigation_Copy',
    'LL_General_Produkte',
    'LL_Footer_Visitenkarten',
    'LL_Footer_Schilder',
    'LL_Footer_Faltschilder',
    'LL_Footer_Flyer',
    'LL_Footer_Broschure',
    'LL_Footer_Alle_Produkte',
    'LL_Footer_Anzeige',
    'LL_General_BenutzerProfil',
    'LL_General_GespeicherteProdukte',
    'LL_General_MeineBestellungen',
    'LL_General_Bilderservice',
    'LL_General_MyMediaManager',
    'LL_General_Konto',
    'LL_Footer_Haftungsausschluss',
    'LL_General_Header_Shop',
    'LL_General_Header_Topics',
    'LL_OnBoarding_Popup_General_Headline',
    'LL_OnBoarding_Popup_1_Headline',
    'LL_OnBoarding_Popup_2_Headline',
    'LL_OnBoarding_Popup_5a_Headline',
    'LL_OnBoarding_Popup_5b_Headline',
    'LL_OnBoarding_Popup_6_Headline',
    'LL_OnBoarding_Popup_7_Headline',
    'LL_OnBoarding_Popup_8_Headline',
    'LL_OnBoarding_Popup_1_Copy',
    'LL_OnBoarding_Popup_2_Copy',
    'LL_OnBoarding_Popup_3_Copy',
    'LL_OnBoarding_Popup_4_Copy',
    'LL_OnBoarding_Popup_5a_Copy',
    'LL_OnBoarding_Popup_5b_Copy',
    'LL_OnBoarding_Popup_6_Copy',
    'LL_OnBoarding_Popup_7a_Copy',
    'LL_OnBoarding_Popup_7b_Copy',
    'RMC_IWI_AV_Copy_1',
    'RMC_IWI_AV_Copy_2',
    'RMC_IWI_AV_Copy_3',
    'RMC_IWI_AV_Copy_4',
    'RMC_IWI_AV_Copy_5',
    'RMC_IWI_AV_Copy_6',
    'LL_OnBoarding_Popup_Download_PortraitBriefing',
    'LL_Profil_PortraitPictureService_Instruction_PDF_Link_File',
    'LL_IWI_Requirement_File',
    'LL_IWI_Requirement_Resolution',
    'RMC_IWI_Image_Select_Button',
    'LL_Profil_FirstName',
    'LL_Profil_LastName',
    'LL_Profil_EmailAddress',
    'LL_IWI_Upload_Fail_TooLarge',
    'LL_IWI_Upload_Fail_Error',
    'LL_IWI_Upload_Success',
    'LL_General_Header_ChooseLanguage',
    'LL_Loginhelper_iConnect_Headline_Directlogin',
    'LL_Loginhelper_Loginassistant_Headline',
    'LL_Login_iConnect',
    'LL_Login_AT',
    'LL_Loginhelper_Loginassistant_Copy_1',
    'LL_Loginhelper_Tutorialvideo_Label_EN',
    'LL_Loginhelper_Tutorialvideo_Label_DE',
    'LL_General_Attention',
    'LL_Login_Benutzername',
    'LL_Login_Passwort',
    'LL_Login_AGB_1',
    'LL_Login_AGB_2',
    'LL_Label_Button_SignUp',
    'LL_NonUpload_Fail_Passwort_PasswortFalsch',
    'LL_NonUpload_Fail_Passwort_Leer',
    'LL_NonUpload_Erstanmeldung_Alias_Leer',
    'LL_Loginhelper_iConnect_Copy_1',
    'LL_Loginhelper_iConnect_Copy_2',
    'LL_Login_AT_Button',
    'LL_Login_AT_Copy',
    'LL_Preisliste_Anzahl',
    'LL_Preisliste_PreisEinzel',
    'LL_Preisliste_PreisGesamt',
    'LL_UserEditPrinting_Stk',
    'LL_ProductDetailPage_CreateProduct',
    'LL_Details_ProduktHinweis_Expired',
    'LL_Details_ProduktHinweis_EndOfLife',
    'LL_Details_ProduktHinweis_OutOfStock',
    'LL_Download',
    'LL_Produktinformation_Kaufen',
    'LL_Loginhelper_Popup_Auswahl_Headline',
    'LL_Loginhelper_Popup_Auswahl_Copy_1',
    'LL_MultiUser_Proceed',
    'LL_Loginhelper_Popup_keinTreffer_Copy',
    'LL_Loginhelper_Popup_vertippt_Copy',
    'LL_Loginhelper_Popup_Regionaldirektion_Copy',
    'LL_Tutorial_Capice',
    'LL_Loginhelper_Popup_mehrereAccounts_Copy_1',
    'LL_Loginhelper_Popup_mehrereAccounts_Copy_2',
    'LL_Loginhelper_Popup_mehrereAccounts_Copy_3',
    'LL_Loginhelper_Popup_Button_Senden',
    'LL_NonUpload_E-Mail-gesendet',
    'LL_General_Abbrechen',
    'LL_General_Error',
    'LL_Loginhelper_Popup_nicht_registriert_Copy_1',
    'LL_Loginhelper_Popup_nicht_registriert_Copy_2',
    'LL_Loginhelper_Popup_iConnectUser_Copy_1',
    'LL_Loginhelper_Popup_iConnectUser_Copy_2',
    'LL_Loginhelper_Popup_iConnectUser_Copy_3',
    'LL_Loginhelper_Popup_iConnectUser_Copy_4',
    'LL_Loginhelper_Popup_iConnectUser_Copy_5',
    'LL_Loginhelper_Popup_iConnectUser_Copy_6',
    'LL_Loginhelper_Popup_iConnectUser_Copy_7',
    'LL_Loginhelper_Popup_registriert_Copy_1',
    'LL_Loginhelper_Popup_registriert_Copy_2',
    'LL_Loginhelper_Popup_registriert_Copy_3',
    'LL_Loginhelper_Button_PW',
    'LL_Loginhelper_Button_BN',
    'LL_General_Header_NoResult1',
    'LL_General_Header_NoResult2',
    'LL_Navi_Subjects_Seasonal',
    'LL_Navi_Subjects_Seasonal_Christmas',
    'BL_Label_TemplateKey',
    'LL_UserEditCustom_Spezifikationen',
    'LL_UserEditCustom_Preise',
    'LL_Details_ProduktDetails_Headline_RelatedProducts',
    'LL_UserEditCustom_Description',
    'LL_ShoppingCart_Vormerken',
    'LL_ShoppingCart_Kopieren',
    'LL_ShoppingCart_Vorschau',
    'LL_ShoppingCart_Loeschen',
    'LL_ShoppingCart_ZumWarenkorb',
    'LL_ShoppingCart_JustSaved',
    'LL_ShoppingCart_ConfirmDelete',
    'LL_ShoppingCart_Delete_Success',
    'LL_ShoppingCart_Delete_NoSuccess',
    'LL_ShoppingCart_ExklMwst',
    'LL_ShoppingCart_Search',
    'LL_ShoppingCart_Section',
    'LL_Checkout_Warenkorb_Headline',
    'LL_ShoppingCart_Section_OnHold',
    'LL_ShoppingCart_Section_InEdit',
    'LL_General_GespeicherteProdukteLeer',
    'LL_Checkout_WarenkorbLeer',
    'LL_General_Header_BuyCredit',
    'LL_General_all',
    'LL_General_Bestellhistorie',
    'LL_ShoppingCart_Rename_Popup',
    'LL_ShoppingCart_Umbenennen',
    'LL_ShoppingCart_Copy',
    'LL_ShoppingCart_NewName',
    'LL_ShoppingCart_SearchProduct',
    'LL_ShoppingCart_NoSearchResult',
    'LL_ShoppingCart_Artikel',
    'LL_ShoppingCart_Änderungsdatum',
    'LL_ShoppingCart_Menge',
    'LL_ShoppingCart_Zurueck',
    'LL_ShoppingCart_Weiter',
    'LL_Discount_Cart_OfficeFlexAbo',
    'LL_General_Versandkosten',
    'LL_General_Zwischensumme',
    'LL_Warning_EndOfLife_Copy1',
    'LL_Warning_EndOfLife_Copy2',
    'LL_Discount_Cart_DeinRabattPreis',
    'LL_General_Header_MySavedProducts',
    'LL_General_Header_ShoppingCart_Preview',
    'LL_General_Header_ShoppingCart_Amount',
    'LL_General_Header_ShoppingCart_Price',
    'LL_Discount_ShoppingCart_Button_Aktivieren',
    'LL_General_Header_ShoppingCart_Tooltip',
    'LL_General_Header_ShoppingCart_ChangeDate',
    'LL_Discount_Aktivieren_Ungueltig_Spezifisch_NichtVerhanden',
    'LL_Discount_Aktivieren_Ungueltig_Spezifisch_NichtZeit',
    'LL_Discount_Aktivieren_Ungueltig_Spezifisch_NichtBuero',
    'LL_Discount_Aktivieren_Ungueltig_Spezifisch_NichtUser',
    'LL_Discount_Aktivieren_Ungueltig_InCart',
    'LL_Discount_Aktivieren_Ungueltig_Spezifisch_NichtArtikel',
    'LL_Discount_Aktivieren_Ungueltig_MindestBW',
    'LL_Discount_Aktivieren_Ungueltig_NoOrder',
    'LL_Discount_Aktivieren_Ungueltig_Spezifisch_NichtRegion',
    'LL_Discount_Aktivieren_Ungueltig_OnlyCredit',
    'LL_Discount_Aktivieren_Copytext',
    'LL_Discount_Aktivieren_Headline',
    'LL_Discount_Aktivieren_Placeholder',
    'LL_Discount_Cart_OfficeFlexAbo_Discount',
    'LL_Discount_Cart_Credit',
    'LL_Discount_Cart_Button_CodeEntfernen',
    'LL_Discount_Cart_Wertgutschein_Wert',
    'LL_Discount_Cart_Wertgutschein_Wert2',
    'LL_General_Header_YourCredit',
    'LL_Discount_Aktivieren_Button',
    'LL_Discount_Cart_Ambassador_Discount',
    'LL_Discount_Cart_Ambassador',
    'LL_Discount_Aktivieren_Gueltig_Headline',
    'LL_Discount_Aktivieren_Gueltig_Text_Discount',
    'LL_Discount_Aktivieren_Gueltig_Gutschein',
    'LL_Discount_Cart_Verwendung_unlimitiert',
    'LL_Discount_Aktivieren_Ungueltig_Allgemein_Headline',
    'LL_Discount_Aktivieren_Ungueltig_Allgemein_Subline',
    'LL_Discount_Aktivieren_Ungueltig_Allgemein_Button',
    'LL_Discount_Aktivieren_Gueltig_UsagesLeft',
    'LL_Discount_Aktivieren_Gueltig_DiscountOnEntireCart',
    'LL_General_Bearbeiten',
    'LL_ShoppingCart_ConfirmDelete_01',
    'LL_ShoppingCart_ConfirmDelete_02',
    'LL_ShoppingCart_ConfirmDelete_Multiple_01',
    'LL_ShoppingCart_ConfirmDelete_Multiple_02',
    'LL_OrderListSummary_NeuBestellen',
    'LL_ShoppingCart_Warenkorb_Subline',
    'LL_ShoppingCart_inEdit_Subline',
    'LL_ShoppingCart_Watchlist_Subline',
    'LL_General_Yes',
    'LL_VAT_NotValid',
    'LL_VAT_WhileValidation_Copy_1',
    'LL_VAT_Warning_CheckOut_Copy_5',
    'LL_VAT_Warning_CheckOut_Copy_8',
    'LL_VAT_WhileValidation_Copy_2',
    'LL_Homepage_Corona_Userinfo_Headline',
    'LL_Homepage_Corona_Userinfo_Copy_1',
    'LL_Homepage_Corona_Userinfo_Copy_2',
    'LL_Homepage_Corona_Userinfo_Copy_3',
    'LL_General_Lieferadresse',
    'LL_CheckoutShipping_NeueAdresse',
    'LL_CheckoutShipping_NeueAdresseSpeichern',
    'LL_Profil_RechFirmierung',
    'LL_Profil_FirstName_LastName',
    'LL_Profil_Address1',
    'LL_Profil_City',
    'LL_Profil_PostalCode',
    'LL_Profil_Country',
    'LL_General_bits_Telefon_Suffix_lang',
    'LL_Profile_Phone',
    'LL_CheckoutPayment_Rechnungsadresse',
    'LL_CheckoutPayment_CCEmailAddress_Head',
    'LL_CheckoutPayment_CCEmailAddress_Copy',
    'LL_CheckoutPayment_CCEmailAddress_Important',
    'LL_Profil_RechEmailAddress_Placeholder',
    'LL_CheckoutPayment_Rechnung',
    'LL_CheckoutPayment_InfoxBox_Rechnung',
    'LL_CheckoutPayment_InfoxBox_Vorkasse',
    'LL_CheckoutPayment_InfoxBox_Paypal',
    'LL_CheckoutSubmit_AGB_Text',
    'LL_CheckoutSubmit_AGB',
    'LL_CheckoutSubmit_InvoiceAddress_Confirm',
    'LL_Checkout_Newsletter_Subscribe',
    'LL_CheckoutSubmit_Datenschutz',
    'LL_CheckoutSubmit_Kauf_Durchfuehren',
    'LL_OrderList_Bestellungen',
    'LL_General_Gewicht',
    'LL_General_zzgl_X_Mehrwertsteuer',
    'LL_General_AnzahlPakete',
    'LL_General_Anzahl_Auslieferungen',
    'LL_Header_Service_Lieferung_Versandkosten',
    'LL_Header_Service_Zahlung_Zahlungsmoeglichkeiten',
    'LL_Checkout_EditAddressInProfile',
    'LL_RegEx_Email_EmailNotValid',
    'LL_ShippingAddress_ConfirmDelete',
    'LL_ShippingAddress_Deleted',
    'LL_Checkout_Header',
    'LL_Checkout_Back',
    'LL_CheckoutSubmit_Approved_Headline',
    'LL_CheckoutSubmit_Approved_Text',
    'LL_CheckoutSubmit_Approved_Copy_1',
    'LL_CheckoutSubmit_Approved_Copy_1_1',
    'LL_CheckoutSubmit_Approved_Copy_1_2',
    'LL_CheckoutSubmit_Approved_Copy_2',
    'LL_CheckoutSubmit_Approved_Copy_3',
    'LL_CheckoutSubmit_Approved_Copy_3a',
    'LL_CheckoutSubmit_Approved_Copy_3b',
    'LL_CheckoutSubmit_Approved_Copy_4a',
    'LL_CheckoutSubmit_Approved_Copy_4b',
    'LL_CheckoutSubmit_Approved_Copy_5',
    'LL_CheckoutSubmit_Approved_Copy_6a',
    'LL_CheckoutSubmit_Approved_Copy_6b',
    'LL_CheckoutSubmit_Approved_Link',
    'LL_OrderList_Bestell_Artikel_Nr',
    'LL_OrderList_Positionen',
    'LL_OrderList_Delivery_Status',
    'LL_OrderListSummary_BestellDatum',
    'LL_OrderList_From',
    'LL_OrderList_Until',
    'LL_OrderList_Search',
    'LL_OrderList_in_Bearbeitung',
    'LL_OrderList_Zugestellt',
    'LL_OrderList_Details',
    'LL_OrderList_in_Zustellung',
    'LL_OrderList_Invoice_Download',
    'LL_OrderListSummary_Bestellung',
    'LL_Profil_Kombi_PostalCode_City',
    'LL_Profil_Telefon',
    'LL_Checkout_Lieferadresse',
    'LL_OrderListSummary_BestellZeit',
    'LL_OrderListSummary_BestellNr',
    'LL_ShoppingCart_MyOrders_Subline',
    'LL_ShoppingCart_Overview',
    'LL_ShoppingCart_OrderPeriod',
    'LL_ShoppingCart_Warning_100_Headline',
    'LL_ShoppingCart_Warning_100_Copy_1',
    'LL_ShoppingCart_Warning_100_Copy_2',
    'LL_ShoppingCart_Warning_100_Copy_3',
    'LL_ShoppingCart_Warning_100_Copy_4',
    'LL_ShoppingCart_Orderinfo',
    'LL_UserEditPrinting_zzglMwst',
    'LL_UserEditPrinting_Gesamtpreis',
    'LL_Header_Menu',
    'LL_Tutorials',
    'LL_General_Header_Logout',
    'LL_FAQ',
    'LL_Flipbook',
    'LL_NonUpload_Profil_Passwort_Aendern_Link',
    'LL_UserMenu_Headline',
    'LL_Sprache_en-GB',
    'LL_Sprache_de-DE',
    'LL_Sprache_de-AT',
    'LL_Sprache_de-CH',
    'LL_Sprache_de-LU',
    'LL_Sprache_fr-BE',
    'LL_Sprache_nl-BE',
    'LL_Sprache_cs-CZ',
    'LL_Sprache_et-EE',
    'LL_Sprache_es-ES',
    'LL_Sprache_hr-HR',
    'LL_Sprache_fr-FR',
    'LL_Sprache_fr-LU',
    'LL_Sprache_fr-CH',
    'LL_Sprache_it-IT',
    'LL_Sprache_it-CH',
    'LL_Sprache_lv-LV',
    'LL_Sprache_lt-LT',
    'LL_Sprache_hu-HU',
    'LL_Sprache_mt-MT',
    'LL_Sprache_nl-NL',
    'LL_Sprache_sr-ME',
    'LL_Sprache_nb-NO',
    'LL_Sprache_pl-PL',
    'LL_Sprache_pt-PT',
    'LL_Sprache_ro-RO',
    'LL_Sprache_sq-AL',
    'LL_Sprache_sk-SK',
    'LL_Sprache_sl-SI',
    'LL_Sprache_fi-FI',
    'LL_Sprache_sv-SE',
    'LL_Sprache_el-GR',
    'LL_Sprache_el-CY',
    'LL_Sprache_bg-BG',
    'LL_Sprache_sr-RS',
    'LL_Sprache_is-IS',
    'LL_General_Schliessen',
    'LL_Kontakt_Anliegen_Copy',
    'LL_Kontakt_Anliegen_Headline',
    'LL_Kontakt_Anliegen_Hilfe_Info',
    'LL_Kontakt_Anliegen_Klassifikation',
    'LL_Kontakt_Anliegen_Loesung_Headline',
    'LL_Kontakt_Anliegen_Tipp_Headline',
    'LL_Kontaktformular_Anhang_Subline',
    'LL_Kontaktformular_Erfolgsmeldung_Headline',
    'LL_Kontaktformular_Erfolgsmeldung_Text',
    'LL_Kontaktformular_IhreNachricht',
    'LL_Kontaktformular_Kontaktdaten',
    'LL_Kontaktformular_Message',
    'LL_Kontaktformular_PopUpHeader_Kontaktformular',
    'LL_Kontaktformular_Subject',
    'LL_Kontaktformular_Submit',
    'LL_Profil_OfficeFirmierung',
    'LL_Profil_Bueroname',
    'LL_Kontaktformular_Herkunftsland',
    'LL_Kontaktformular_Sprache',
    'LL_Profil_Mobil',
    'LL_Kontakt_Anliegen_Dropdown_Grund1',
    'LL_Kontakt_Anliegen_Grund1_Problem1',
    'LL_Kontakt_Anliegen_Grund1_Problem2',
    'LL_Kontakt_Anliegen_Grund1_Problem3',
    'LL_Kontakt_Anliegen_Grund1_Tipp',
    'LL_Kontakt_Anliegen_Grund1_Loesung',
    'LL_Kontakt_Anliegen_Dropdown_Grund2',
    'LL_Kontakt_Anliegen_Grund2_Problem1',
    'LL_Kontakt_Anliegen_Grund2_Problem2',
    'LL_Kontakt_Anliegen_Grund2_Problem3',
    'LL_Kontakt_Anliegen_Grund2_Tipp',
    'LL_Kontakt_Anliegen_Grund2_Loesung',
    'LL_Kontakt_Anliegen_Dropdown_Grund3',
    'LL_Kontakt_Anliegen_Grund3_Problem1',
    'LL_Kontakt_Anliegen_Grund3_Problem2',
    'LL_Kontakt_Anliegen_Grund3_Problem3',
    'LL_Kontakt_Anliegen_Grund3_Tipp',
    'LL_Kontakt_Anliegen_Grund3_Loesung',
    'LL_Kontakt_Anliegen_Dropdown_Grund4',
    'LL_Kontakt_Anliegen_Grund4_Problem1',
    'LL_Kontakt_Anliegen_Grund4_Problem2',
    'LL_Kontakt_Anliegen_Grund4_Problem3',
    'LL_Kontakt_Anliegen_Grund4_Tipp',
    'LL_Kontakt_Anliegen_Grund4_Loesung',
    'LL_Kontakt_Anliegen_Dropdown_Grund5',
    'LL_Kontakt_Anliegen_Grund5_Problem1',
    'LL_Kontakt_Anliegen_Grund5_Problem2',
    'LL_Kontakt_Anliegen_Grund5_Problem3',
    'LL_Kontakt_Anliegen_Grund5_Tipp',
    'LL_Kontakt_Anliegen_Grund5_Loesung',
    'LL_Kontakt_Anliegen_Dropdown_Grund6',
    'LL_Kontakt_Anliegen_Grund6_Problem1',
    'LL_Kontakt_Anliegen_Grund6_Problem2',
    'LL_Kontakt_Anliegen_Grund6_Problem3',
    'LL_Kontakt_Anliegen_Grund6_Tipp',
    'LL_Kontakt_Anliegen_Grund6_Loesung',
    'LL_Kontakt_Anliegen_Dropdown_Grund7',
    'LL_Kontakt_Anliegen_Grund7_Problem1',
    'LL_Kontakt_Anliegen_Grund7_Problem2',
    'LL_Kontakt_Anliegen_Grund7_Problem3',
    'LL_Kontakt_Anliegen_Grund7_Tipp',
    'LL_Kontakt_Anliegen_Grund7_Loesung',
    'LL_Kontakt_Anliegen_Dropdown_Grund8',
    'LL_Kontakt_Anliegen_Grund8_Problem1',
    'LL_Kontakt_Anliegen_Grund8_Problem2',
    'LL_Kontakt_Anliegen_Grund8_Problem3',
    'LL_Kontakt_Anliegen_Grund8_Tipp',
    'LL_Kontakt_Anliegen_Grund8_Loesung',
    'LL_Kontakt_Anliegen_Dropdown_Grund9',
    'LL_Kontakt_Anliegen_Grund9_Problem1',
    'LL_Kontakt_Anliegen_Grund9_Problem2',
    'LL_Kontakt_Anliegen_Grund9_Problem3',
    'LL_Kontakt_Anliegen_Grund9_Tipp',
    'LL_Kontakt_Anliegen_Grund9_Loesung',
    'LL_Kontakt_Anliegen_Dropdown_Grund10',
    'LL_Kontakt_Anliegen_Grund10_Problem1',
    'LL_Kontakt_Anliegen_Grund10_Problem2',
    'LL_Kontakt_Anliegen_Grund10_Problem3',
    'LL_Kontakt_Anliegen_Grund10_Tipp',
    'LL_Kontakt_Anliegen_Grund10_Loesung',
    'LL_Kontakt_Anliegen_Dropdown_Grund11',
    'LL_Kontakt_Anliegen_Grund11_Problem1',
    'LL_Kontakt_Anliegen_Grund11_Problem2',
    'LL_Kontakt_Anliegen_Grund11_Problem3',
    'LL_Kontakt_Anliegen_Grund11_Tipp',
    'LL_Kontakt_Anliegen_Grund11_Loesung',
    'LL_General_CharsNumbers_Allowed',
    'LL_General_Max_Number_Charts',
    'LL_ProdMon_Mail_PayDetails_1',
    'LL_CheckoutPayment_Button_Rechnung',
    'LL_CheckoutPayment_Button_Paypal_CC_other',
    'LL_General_bits_Telefon_Suffix_Chars_14',
    'LL_Themen_SelfPromotion_AddOn_Button2',
    'LL_General_ShippingAddress',
    'BL_Label_Pflichtfeld',
    'LL_Shop_Communication_UserBlocked_1',
    'LL_ProdMon_Mail_Infotext_3',
    'LL_Profil_Accountdaten',
    'LL_Profil_Headline_Personendaten',
    'LL_Profil_Headline_OfficeAdresse',
    'LL_Profil_UstID_Headline_Profil',
    'LL_Profil_Headline_PortraitPicture',
    'LL_OnBoarding_Popup_Bodyphoto_Headline',
    'LL_General_Speichern',
    'LL_Profile_Headline_Rechnungsdaten',
    'LL_Profile_Newsletter',
    'LL_Shop_Communication_ProfileComplete_1',
    'LL_Shop_Communication_ProfileComplete_2',
    'LL_Profil_Vervollstaendigkeitsanzeige_1',
    'LL_Profile_Note_1',
    'LL_Profile_Note_2',
    'LL_Profile_Note_3',
    'LL_Discount_Cart_Guthaben_UserInfo_1',
    'LL_Discount_Cart_Credit_Guthaben_Head',
    'LL_Profile_Guthaben_Aufladen',
    'LL_NonUpload_Dein_Alias',
    'LL_NonUpload_PopUpHead_Passwort',
    'LL_ServiceInfo_Customer_ID',
    'LL_Profile_Portrait_Header_Ready',
    'LL_Profile_Portrait_Body_Ready',
    'LL_Profil_PortraitPictureService_Infotext_1',
    'LL_Profil_PortraitPictureService_Infotext_2',
    'LL_Profil_PortraitPictureService_Infotext_2_1',
    'LL_Profil_PortraitPictureService_Infotext_2_2',
    'LL_Profil_PortraitPictureService_Instruction_PDF_Link_Text',
    'LL_Profile_Portrait_Header_In_Process',
    'LL_Profile_Portrait_Body_In_Process',
    'LL_Profile_Portrait_Download_JPG',
    'LL_Profile_Portrait_Button_In_Process',
    'LL_Shop_Communication_Profile_Incomplete_1',
    'LL_Shop_Communication_Profile_Incomplete_2',
    'LL_Shop_Communication_Link_Label_EditProfile',
    'BL_ShoppingCart_Artikel_Copy_OK',
    'BL_ShoppingCart_Artikel_Rename_OK',
    'BL_ShoppingCart_Artikel_Deleted_OK',
    'LL_NonUpload_PopUpHead_Benutzername',
    'LL_NonUpload_Profil_Alias_Aendern_Copy_1',
    'LL_NonUpload_Profil_Alias_Label',
    'LL_NonUpload_Profil_Passwort_Aendern_Copy_1',
    'LL_NonUpload_Profil_Passwort_Aendern_PW_aktuell',
    'LL_NonUpload_Profil_Passwort_Aendern_PW_neu',
    'LL_NonUpload_Profil_Passwort_Aendern_PW_neu_2',
    'LL_NonUpload_Fail_Passwort_Unterschied',
    'LL_General_Template',
    'BL_Profile_Newsletter_Widget_1_Img',
    'BL_Profile_Newsletter_Widget_1_Copy',
    'BL_Profile_Newsletter_Widget_2_Img',
    'BL_Profile_Newsletter_Widget_2_Copy',
    'BL_Profile_Newsletter_Widget_2_Headline',
    'LL_onOffice_Info_V3_02_Freischaltung_Userprofil_01',
    'LL_onOffice_Info_V3_02_Freischaltung_Userprofil_02',
    'LL_onOffice_Info_V3_02_Freischaltung_Userprofil_03',
    'LL_onOffice_Info_V3_02_Freischaltung_Userprofil_04',
    'LL_onOffice_Info_V3_02_Freischaltung_Userprofil_Status_01',
    'LL_onOffice_Info_V3_02_Verbindungsstatus_Servicedaten',
    'LL_onOffice_Info_V3_02_Freischaltung_Userprofil_05',
    'LL_onOffice_Info_V3_02_Freischaltung_Userprofil_06',
    'LL_onOffice_Info_V3_02_Freischaltung_Userprofil_07',
    'LL_onOffice_Info_V3_02_Freischaltung_Userprofil_08',
    'LL_onOffice_Info_V3_02_Freischaltung_Userprofil_09',
    'LL_onOffice_Info_V3_02_Freischaltung_Userprofil_Status_02',
    'LL_onOffice_Info_V3_02_Freischaltung_Userprofil_entfernen',
    'LL_onOffice_Info_V3_02_Trennen_Copy_1',
    'LL_onOffice_Info_V3_02_Trennen_Copy_2',
    'LL_onOffice_Info_V3_02_Trennen_Headline',
    'LL_General_Bestaetigen',
    'LL_onOffice_Info_V3_02_Freischaltung_Schritt_2',
    'LL_onOffice_Info_V3_02_Freischaltung_Schritt_2_01',
    'LL_onOffice_Info_V3_02_Freischaltung_Schritt_2_02',
    'LL_Profil_FirmierungMakler_Placeholder',
    'LL_Profil_FirmierungMakler',
    'LL_Profil_Funktion2_Placeholder',
    'LL_Profil_Funktion1_Placeholder',
    'LL_Profil_Funktion1',
    'LL_Profil_Funktion2',
    'LL_Profil_EmailAddress_Placeholder',
    'LL_Profil_Fax',
    'LL_Profil_Bueroname_Placeholder',
    'LL_Profil_OfficeFirmierung_Placeholder',
    'LL_Profil_OfficeFirmierung',
    'LL_Profil_Address2',
    'LL_Profil_Address2_Placeholder',
    'LL_VAT_Kundeninformation',
    'LL_Profil_UstID',
    'LL_Profil_UstID_Syntax_Copy_2',
    'LL_Label_Button_Bildersammlung',
    'LL_Profil_Benutzerkonto',
    'LL_CheckoutPayment_Submit_Error_Copy',
    'LL_Profile_Saved_Toast', // Profile saved
    'LL_Profile_CC', // CC
    'LL_Profile_UstIdValidieren', // UST ID Validieren
    'LL_Profile_UstIdLandFormatText', //  Die Umsatzsteuer ID-Nr. deines Landes ist nach folgendem Schema aufgebaut:
    'LL_Profile_UstIdEuKommissionLink', //  Überprüfe deine UstID bei der Europäischen Kommission
    'LL_Profile_UstIdInfoboxText', //  Entweder die Validierung deiner UstID ist abgelaufen oder es wurde bisher keine Validierung vorgenommen. Gehe zur UstID und klicke dort auf UstID validieren/aktualisieren und abschließend auf “Speichern”.
    'LL_Profile_ProfilFoto_InBearbeitung', // Auftrag in Bearbeitung
    'LL_Profile_ProfilFoto_Label_1', // Your Image is ready to use in our templates!
    'LL_Profile_ProfilFoto_Label_2', // Deine freigestellten Fotos
    'LL_Profile_ProfilFoto_Label_3', // We are working on your uploaded image.
    'LL_Profile_ProfilFoto_Text_1', // Your image will be placed automatically in various templates like business-cards, flyers and brochures.
    'LL_Profile_ProfilFoto_Text_2', // Hier kannst du deine bisherigen freigestellten Profilbilder anschauen sowie neue Bilder in Auftrag geben.
    'LL_Profile_ProfilFoto_Text_3', // You will receive a message, as soon as your image is ready-to-use.
    'LL_Profile_OnOffice_Headline', // On-Office
    'LL_Login_MarketingCenter',
    'LL_VAT_OutOfService',
    'LL_Profil_Template_Optional_Headline_1',
    'LL_Profil_Template_Optional_Copy_1',
    'LL_Profil_PostalCode_Placeholder',
    'LL_Profile_Newsletter_Checkbox',
    'LL_StopNewsletter_Infobox_Copy1',
    'LL_StopNewsletter_Infobox_Copy2',
    'LL_Profil_OfficeEmailAddress',
    'LL_Profil_OfficeEmailAddress_Placeholder',
    'LL_Profil_Webseite',
    'LL_Profil_Webseite_Placeholder',
    'LL_CheckoutPayment_Submit_Error_01',
    'LL_CheckoutPayment_Submit_Error_02',
    'LL_CheckoutPayment_Submit_Error_03',
    'LL_OrderList_Delivery_Tracking',
    'LL_OrderList_Delivery_Details',
    'LL_OrderList_Delivery_Scan',
    'LL_OrderList_Delivery_Road',
    'LL_OrderList_Delivery_Vehicle',
    'LL_OrderList_Delivery_Approx',
    'LL_OrderList_Delivery_Tracking',
    'LL_OrderList_Delivery_When',
    'LL_OrderList_Delivery_Status',
    'LL_OrderList_Delivery_Docs',
    'LL_OrderList_Zugestellt',
    'LL_OrderList_Delivery_Where',
    'LL_CheckoutSubmit_Approved_Copy_3b',
    'LL_Packages',
    'LL_Signature',
    'LL_TrackingCode_NoDataAvailableForCode',
    'LL_TrackingCode_Label',
    'LL_OnBoarding_Popup_8_Headline',
    'RMC_IWI_AV_Copy_1',
    'RMC_IWI_AV_Copy_2',
    'RMC_IWI_AV_Copy_3',
    'RMC_IWI_AV_Copy_4',
    'RMC_IWI_AV_Copy_5',
    'RMC_IWI_AV_Copy_6',
    'LL_OnBoarding_Popup_General_Headline',
    'LL_OnBoarding_Popup_1_Headline',
    'LL_OnBoarding_Popup_1_Copy',
    'LL_OnBoarding_Popup_2_Copy',
    'LL_OnBoarding_Popup_Download_PortraitBriefing',
    'LL_OnBoarding_Popup_2_Headline',
    'LL_OnBoarding_Popup_3_Copy',
    'LL_OnBoarding_Popup_4_Copy',
    'LL_OnBoarding_Popup_5a_Headline',
    'LL_OnBoarding_Popup_5b_Headline',
    'LL_OnBoarding_Popup_5a_Copy',
    'LL_OnBoarding_Popup_5b_Copy',
    'LL_OnBoarding_Popup_6_Headline',
    'LL_OnBoarding_Popup_6_Copy',
    'LL_IWI_Requirement_File',
    'LL_IWI_Requirement_Resolution',
    'LL_IWI_Warning_MissingQuality',
    'LL_OnBoarding_Popup_7_Headline',
    'LL_OnBoarding_Popup_7a_Copy',
    'LL_OnBoarding_Popup_7b_Copy',
    'LL_OnBoarding_Popup_8_Headline',
    'LL_OnBoarding_Popup_Step_Next',
    'LL_OnBoarding_Popup_Step_Prev',
    'LL_OnBoarding_Popup_Step_Send',
    'LL_General_DigitaleVisitenkarte',
    'LL_Checkout_MBW_Hinweis_Headline',
    'LL_Checkout_MBW_Hinweis_Copy_1',
    'LL_Checkout_MBW_Hinweis_Mouseover',

    'LL_Tracking_Userprofil_Headline',
    'LL_Tracking_Userprofil_Copy_1',
    'LL_Tracking_Userprofil_Copy_2',
    'LL_Tracking_Warenkorb_Emailadresse',
    'LL_Tracking_Userprofil_Copy_3',
    'LL_Tracking_Userprofil_Copy_4',

    'LL_Tracking_Warenkorb_Headline',
    'LL_Tracking_Warenkorb_Check',
    'LL_Tracking_Warenkorb_Emailadresse',
    '',
    ...digiVKLocales,
];

export const useAllLocales = () => {
    const { data: locales } = useLocalization(localeArray);

    return locales;
};
